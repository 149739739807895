/**
 * Kangaroo Checkoout Bootstrap theme
 * read more about theming here:
 * https://getbootstrap.com/docs/4.5/getting-started/theming/
 *
 * view all bootstrap variables:
 * https://github.com/twbs/bootstrap/blob/v4.5.0/scss/_variables.scss
 */

// Colors
$primary: #334d80;

// Grid
$grid-gutter-width: 24px;

// Default bootstrap breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1280px // our container max-width,,,,,,
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1280px,
);

$font-size-root: 16px;
$font-size-base: 1rem;

:root {
	--tt-bg-color: #e2e2e2;
	--white-teal: $white-teal;
}

@import '~bootstrap/scss/bootstrap';
