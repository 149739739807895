.shippingMethodContainer {
	max-width: 600px;
	width: 100%;
	border: 1px solid #deddd9;
	border-radius: 4px;
	margin-top: 2rem;
}
.invalidShipping {
	min-height: 100px;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	& span {
		color: #999994;
	}
}

.label {
	display: flex;
	& div {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 18px;
	}
	cursor: pointer;
	padding: 1.5rem;
}

.inputRow {
	border-bottom: 1px solid #deddd9;
	padding-left: 0px !important;
}

.check {
	padding-left: 0px !important;
}
.input {
	display: none;
	&:checked + *::before {
		border-color: #5a91fe;
		border-width: 7.5px;
	}
	&:checked + label {
		background-color: #f7f7f7;
	}
	& + *::before {
		content: '';
		display: inline-block;
		margin-right: 8px;
		width: 30px;
		min-width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 2px solid #deddd9;
		box-sizing: border-box;
	}
}
