@import './theme.scss'; // bootstrap is imported in here
@import './mixins.scss';
@import './typography.scss';

body {
	margin: 0;
	padding: 0;
}

.checkout-submit {
	padding: 17px 30px 17px 30px;
	@include to-bp(lg) {
		width: 100%;
	}
}

select {
	-webkit-appearance: none;
}

.checkout-form {
	height: 60px;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 21px;
	color: black;
}

.invalid-msg {
	color: #dc3545;
	padding-top: 0.5rem;
	font-size: 14px;
}
.loader {
	display: flex;
	align-items: center;
	justify-content: center;
}

.card {
	border: none;
}

.card-header {
	background-color: transparent;
	border: none;
}

.card-body {
	@include from-bp(lg) {
		margin-left: 3em;
	}
}

.spinner-border {
	width: 1rem;
	height: 1rem;
}
