.header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 2em;
}

.logo {
	display: inline-block;
	line-height: 66px;
	& img {
		margin-top: 18px;
	}
}
