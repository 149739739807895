.orderSummary {
	width: 40%;
	margin-bottom: 25px;
	& .brief {
		display: none;
	}
	& .brief {
		display: none;
	}
	& .show {
		display: block !important;
	}
	& .hide {
		display: none !important;
	}
	& em {
		white-space: nowrap;
	}
}

@media screen and (max-width: 992px) {
	.orderSummary {
		width: 100%;
		& .detailed {
			display: none;
		}
		& .brief {
			display: block;
		}
	}
}
