.errorContainer {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.errorWrap {
	text-align: center;
	& h1 {
		font-size: 55px;
		font-style: normal;
		font-weight: 700;
		line-height: 65px;
		letter-spacing: 0px;
	}
	& p {
		font-size: 21px;
		font-style: normal;
		font-weight: 400;
		line-height: 34px;
		letter-spacing: 0px;
		color: #999994;
		margin-bottom: 2rem;
	}
	& a {
		display: inline-block;
		font-weight: bold;
		background: #fff166;
		color: #334d80;
		text-decoration: none;
		width: 100%;
		text-align: center;
		box-sizing: border-box;
		padding: 15px 15px 13px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 18px;
		border: none;
	}
}
