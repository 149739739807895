.summaryItemCard {
	display: flex;
	flex-direction: row;
	margin-right: 15px;
	& .imageWrap {
		width: 80px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		& img {
			max-width: 80px;
			max-height: 80px;
			width: auto;
			height: auto;
			margin-right: 15px;
			margin-bottom: 15px;
		}
	}
	& h4 {
		font-weight: normal;
		font-size: 18px;
		line-height: 30px;
	}
}

.itemQuantity {
	opacity: 0.7;
	font-size: large;
}

@media screen and (max-width: 992px) {
	.summaryItemCard {
		& .imageWrap {
			width: 60px;
			height: 60px;
			& img {
				max-height: 60px;
			}
		}
		& h4 {
			font-size: 12px;
			line-height: 18px;
		}
	}
}
