@import '~/styles/mixins.scss';

.storePage {
	& .main {
		margin: 80px 0;
		@include to-bp(lg) {
			margin: 25px 0 50px;
		}
		& > .container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			max-width: 1400px;
			width: 100%;
			padding: 0 15px;
			box-sizing: border-box;
			margin: 0 auto;
			@include to-bp(lg) {
				flex-direction: column-reverse;
			}
		}
	}
}

.confirmationMsg {
	padding: 2rem;
}

.continueShopping {
	margin-top: 2rem;
}
