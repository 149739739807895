@font-face {
	font-family: 'Sofia pro';
	font-style: normal;
	font-weight: normal;
	src: url('~/styles/fonts/Sofia-Pro-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Sofia pro';
	font-style: normal;
	font-weight: bold;
	src: url('~/styles/fonts/Sofia-Pro-Bold.otf') format('opentype');
}

* {
	font-family: 'Sofia pro', sans-serif;
}

body {
	font-family: 'Sofia pro', sans-serif;
	font-weight: normal;
	font-size: 16px;
}

a,
a:hover,
a:visited,
a:active {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0 0 15px;
	font-style: normal;
	font-weight: bold;
}

h1,
.h1 {
	font-size: 34px;
	line-height: 45px;
	letter-spacing: normal;
}

h2,
.h2 {
	font-size: 28px;
	line-height: 34px;
}

h3,
.h3 {
	font-size: 21px;
	line-height: 28px;
}

h4,
.h4 {
	font-size: 14px;
	line-height: 24px;
}

h5,
.h5 {
	font-size: 13px;
	line-height: 21px;
}

h6,
.h6 {
	font-size: 12px;
	line-height: 18px;
}

.sm {
	font-size: 12px;
	line-height: 18px;
}

.sm2 {
	font-size: 11px;
	line-height: 17px;
}

p {
	margin: 0 0 10px;
	font-size: 16px;
	line-height: 28px;
}

.p2 {
	margin: 0 0 10px;
	font-size: 18px;
	line-height: 30px;
}

ul {
	padding-left: 14px;
	font-size: 14px;
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 15px;
}

li {
	font-size: 14px;
	line-height: 24px;
}

@media only screen and (min-width: 981px) {
	h1,
	.h1 {
		font-size: 80px;
		line-height: 95px;
		letter-spacing: -2px;
	}

	h2,
	.h2 {
		font-size: 34px;
		line-height: 45px;
	}

	h3,
	.h3 {
		font-size: 28px;
		line-height: 34px;
	}

	h4,
	.h4 {
		font-size: 18px;
		line-height: 30px;
	}

	h5,
	.h5 {
		font-size: 17px;
		line-height: 28px;
	}

	h6,
	.h6 {
		font-size: 16px;
		line-height: 24px;
	}

	.sm {
		font-size: 14px;
		line-height: 24px;
	}

	.sm2 {
		font-size: 13px;
		line-height: 22px;
	}

	p,
	.p {
		margin: 0 0 10px;
		font-size: 18px;
		line-height: 30px;
	}

	.p2 {
		margin: 0 0 10px;
		font-size: 21px;
		line-height: 34px;
	}

	ul {
		padding-left: 18px;
		font-size: 18px;
		line-height: 30px;
		margin-top: 0;
		margin-bottom: 15px;
	}

	li {
		font-size: 18px;
		line-height: 30px;
	}
}
