.wrap {
	border: 1px solid #deddd9;
	border-radius: 8px;
	& .alignTop tr td {
		vertical-align: top;
	}
	& h3 {
		font-weight: normal;
		margin: 0;
	}
	& em {
		font-weight: bold;
		font-style: normal;
		font-size: 125%;
	}
	& table {
		& tr {
			height: 36px;
			& td {
				min-width: 60px;
				&:first-of-type {
					width: 100%;
				}
			}
		}
	}
	& > div {
		border-bottom: 1px solid #deddd9;
		padding: 25px;
		&:last-of-type {
			border: 0;
		}
	}
}

.briefSummary {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	& img {
		max-width: 60px;
		max-height: 60px;
	}
	& .totalItems {
		font-weight: bold;
		font-size: 110%;
	}
	& .totalAmount {
		font-weight: bold;
		font-size: 125%;
	}
	& .showDetails {
		cursor: pointer;
		color: #5a91fe;
		font-size: 12px;
	}
}
.couponsWrap {
	margin-top: 1rem;
}

.coupon {
	cursor: pointer;
	color: #5a91fe;
	&:hover {
		text-decoration: underline;
	}
}

.disclaimer {
	font-size: 10px;
	color: gray;
	max-width: 369px;
	margin: 9px 0 0 94px;
	line-height: 14px;
}

.red{
	color: #F24724;
}

.couponName {
	color: green;
}
.removeCoupon {
	cursor: pointer;
	color: red;
}

@media screen and (max-width: 992px) {
	.wrap {
		border: 0;
		& > div {
			padding: 25px 0;
			&:last-of-type {
				border-bottom: 1px solid #deddd9;
			}
		}
	}
}
