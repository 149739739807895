@import '~/styles/mixins.scss';

.checkout {
	width: 100%;
	max-width: 800px;
	margin-bottom: 25px;
}

.cardHeader {
	display: flex;
	justify-content: space-between;
	@include to-bp(lg) {
		margin-left: -20px;
		margin-right: -20px;
	}
}

.cardTitle {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-between;
	@include to-bp(lg) {
		flex-direction: column;
		& div {
			margin-left: 2rem;
		}
	}
	& h3 {
		display: flex;
		align-items: flex-start;
		max-width: 300px;
		width: 100%;
		margin: 0 0 10px;
		padding: 0;
		font-size: 34px;
		line-height: 45px;
		@include to-bp(lg) {
			font-size: 24px;
		}
		& span {
			display: inline-block;
			width: 40px;
			line-height: 30px;
			text-align: left;
			box-sizing: border-box;
			& em {
				display: inline-block;
				text-align: center;
				font-style: normal;
				width: 28px;
				height: 28px;
				color: #ffffff;
				background: #000000;
				border-radius: 50%;
				font-size: 18px;
				line-height: 30px;
				margin-bottom: 10px;
			}
		}
	}
}

.editButton {
	padding-top: 0.25rem;
	& button {
		background-color: transparent;
		border: 2px solid;
		border-radius: 8px;
		font-size: 18px;
		padding: 0.25rem 1rem 0.25rem 1rem;
	}
}

.activeCard {
	& .editButton {
		display: none;
	}
}

.inactiveCard {
	& h3 {
		opacity: 0.5;
	}
}

.children {
	font-size: 18px;
	max-width: 350px;
	width: 100%;
	margin-top: 0.5rem;
	& ul {
		list-style-type: none;
		margin-left: -18px;
		@include to-bp(lg) {
			margin-left: -14px;
		}
	}
}
