@import '~/styles/mixins.scss';

.stripeForm {
	max-width: 528px;
	box-sizing: border-box;
	align-self: center;
	border-radius: 2px;
	& input {
		border-radius: 6px;
		margin-bottom: 6px;
		padding: 12px;
		border: 1px solid rgba(50, 50, 93, 0.1);
		max-height: 44px;
		font-size: 16px;
		width: 100%;
		background: white;
		box-sizing: border-box;
	}
	& .resultMessage {
		line-height: 22px;
		font-size: 16px;
		a {
			color: rgb(89, 111, 214);
			font-weight: 600;
			text-decoration: none;
		}
	}

	& .hidden {
		display: none;
	}
}

.cardError {
	color: rgb(105, 115, 134);
	font-size: 16px;
	line-height: 20px;
	margin-top: 12px;
	text-align: center;
}

.stripeInput {
	border-radius: 4px;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	max-height: 44px;
	width: 100%;
	background: white;
	box-sizing: border-box;
	margin-bottom: 1.5rem;
	box-shadow: 0 3px 3px rgba(182, 182, 182, 0.2);
}

.stripeButton {
	background: #45cc3d;
	color: #ffffff;
	border-radius: 4px;
	border: 0;
	padding: 12px 16px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	display: block;
	transition: all 0.2s ease;
	box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
	width: 100%;
	&:hover {
		filter: contrast(115%);
	}
	&:disabled {
		opacity: 0.5;
		cursor: default;
	}
}

.submitButton {
	@include from-bp(lg) {
		max-width: 150px;
		width: 100%;
	}
}

.terms {
	font-size: 8px;
	line-height: 1.2;
	opacity: 0.4;
	margin-top: 0.5rem;
	& a {
		color: black;
	}
}
